<template>
  <button
    :class="['social-button', { active: isActive }]"
    @click="handleClick"
    @mouseenter="isActive = true"
    @mouseleave="isActive = false"
  >
    <div class="icon" :style="iconStyle"></div>
  </button>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    activeTintColor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    iconStyle() {
      return {
        width: '18px',
        height: '18px',
        background: this.isActive ? this.activeTintColor : '#616161',
        maskImage: `url(${this.image})`,
        WebkitMaskImage: `url(${this.image})`,
        maskSize: 'cover',
        WebkitMaskSize: 'cover'
      };
    }
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>

<style scoped>
.social-button {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  cursor: pointer;
}

.social-button:hover, .social-button:active {
  background-color: #E4E5E5;
}

.icon {
  width: 21px;
  height: 21px;
 
}
</style>
