<template>
  <footer class="footer-divider"></footer>
  <footer class="footer">
    <div class="footer-column footer-left">
      <img src="@/assets/wisher.footer.logo.svg" class="footer-logo" />
      <div class="social-icons">
        <SocialButton :image=instIcon activeTintColor="#ff5722" @click="handleInstagramClick" />
        <SocialButton :image=xIcon activeTintColor="#000000" @click="handleXClick" />
        <SocialButton :image=gitHubIcon activeTintColor="#000000" @click="handleGitHubClick" />
      </div>
      <p class="privacy-title">{{ $t('footer.privacyTitle') }}</p>
      <p class="copyright-title">© 2025 Wisher Lab, TOO</p>
    </div>
    <div class="footer-column footer-middle">
      <h3 class="footer-title">{{ $t('footer.companyColumn') }}</h3>
      <FooterColumnButton :title="$t('footer.contactsLinkTitle')" @click="handleContactsClick" />
      <FooterColumnButton :title="$t('footer.privacyPolicyLinkName')" @click="handlePrivacyClick" />
      <FooterColumnButton :title="$t('footer.userAgreementLinkName')" @click="handleTermsClick" />
    </div>
    <div class="footer-column footer-right">
      <h3 class="footer-title">{{ $t('footer.wishlistColumn') }}</h3>
      <FooterColumnButton title="Telegram" @click="handleTelegramClick" />
      <FooterColumnButton title="iOS" @click="handleiOSClick" />
      <FooterColumnButton title="Android" :tagTitle="$t('footer.androidSoon')" @click="handleAndroidClick" />
    </div>
  </footer>
</template>

<script>
import SocialButton from './SocialButton.vue';
import FooterColumnButton from './FooterColumnButton.vue';
import { analytics, logEvent } from '../main';

export default {
  name: 'AppFooter',
  data() {
    return {
      instIcon: require(`@/assets/instagram-logo.svg`),
      xIcon: require(`@/assets/x-logo.svg`),
      gitHubIcon: require(`@/assets/github-logo.svg`),
    };
  },
  components: {
    SocialButton,
    FooterColumnButton,
  },
  methods: {
    handleXClick() {
      window.open('https://x.com/wisherpro', '_blank');
      logEvent(analytics, 'x_button_click');
    },
    handleInstagramClick() {
      window.open('https://instagram.com/wisher.pro', '_blank');
      logEvent(analytics, 'instagram_button_click');
    },
    handleGitHubClick() {
      window.open('https://github.com/Wisher-pro', '_blank');
      logEvent(analytics, 'github_button_click');
    },
    handleContactsClick() {
      this.$router.push('/contact') 
    },
    handlePrivacyClick() {
      this.$router.push('/privacy') 
    },
    handleTermsClick() {
      this.$router.push('/terms') 
    },
    handleiOSClick() {
      window.open('https://apps.apple.com/ru/app/wisher-pro-%D0%B2%D0%B8%D1%88%D0%BB%D0%B8%D1%81%D1%82-%D0%B8-%D0%BF%D0%BE%D0%B4%D0%B0%D1%80%D0%BA%D0%B8/id6468797180', '_blank');
      logEvent(analytics, 'footer_ios_click');
    },
    handleAndroidClick() {
      logEvent(analytics, 'footer_android_click');
    },
    handleTelegramClick() {
      window.open('https://t.me/wisherprobot', '_blank');
      logEvent(analytics, 'footer_telegram_click');
    },
  }
};
</script>

<style scoped>
.footer-divider {
  height: 1px;
  background-color: #E5E5E5;
  margin-left: 20px;
  margin-right: 20px;
}

.footer {
  background-color: #FFFFFF;
  color: #616161;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px 110px;
  box-sizing: border-box;
  max-width: 1000px; 
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  min-width: 100px;
  max-width: 200px;
}

.footer-left {
  align-items: flex-start;
  gap: 5px;
  margin-left: 10px;
}

.footer-logo {
  height: 34px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.social-icons {
  display: flex;
  gap: 6px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: -5px;
}

.social-icon img {
  width: 20px;
  height: 20px;
}

.privacy-title {
  font-size: 14px;
  color: #000000;
}

.copyright-title {
  font-size: 14px;
  color: #000000;
}

.footer-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000000;
}

@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .footer-column {
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .footer-left {
    margin-left: 10px;
  }
}
</style>
