<template>
  <button class="footer-column-button" v-bind="$attrs" @click="$emit('click')">
    <span class="button-text">{{ title }}</span>
    <span v-if="tagTitle" class="tag-view">{{ tagTitle }}</span>
  </button>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    tagTitle: { type: String, default: "" },
  },
  emits: ["click"],
};
</script>

<style scoped>
.footer-column-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background: none;
  color: #3F3E3B;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 0;
  height: 30px;
}

.button-text {
  text-align: left;
  font-size: 16px;
  line-height: 40px;
  text-decoration: none;
}

.footer-column-button:hover .button-text {
  text-decoration: underline;
  text-decoration-color: #087FE8;
}

.tag-view {
  background-color: #000000;
  color: #FFFFFF;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  margin-left: 8px;
  display: flex;
  align-items: center;
  height: 14px;
}
</style>