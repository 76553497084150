<template>
  <link rel="icon" href="/favicon.ico">
  <div class="main-container">
    <div class="title">{{ $t('main.title') }}</div>
    <!-- <MultiLineTextView :lines=lines class="multiline-text-view" /> -->
    <StackLayout />
    <div class="kinesis-wrapper">
      <kinesis-container :active="true" :strength="20" :boundary="50">
        <img :src="ladyImage" class="lady-image" />
        <kinesis-element v-for="(present, index) in leftPresents" :key="index" :type="'scale'" :strength="0.2"
          class="present" :style="{ top: present.top, right: present.right }">
          <div data-kinesisdistance-item data-ks-strength="15" data-ks-startdistance="2000" class="distance-box">
            <img :src="present.src" class="present-image" />
          </div>
        </kinesis-element>
        <kinesis-element v-for="(present, index) in topPresents" :key="index" :type="'scale'" :strength="0.2"
          class="present" :style="{ bottom: present.bottom, left: present.left }">
          <div data-kinesisdistance-item data-ks-strength="15" data-ks-startdistance="2000" class="distance-box">
            <img :src="present.src" class="present-image" />
          </div>
        </kinesis-element>
        <kinesis-element v-for="(present, index) in rightPresents" :key="index" :type="'scale'" :strength="0.2"
          class="present" :style="{ top: present.top, left: present.left }">
          <div data-kinesisdistance-item data-ks-strength="15" data-ks-startdistance="2000" class="distance-box">
            <img :src="present.src" class="present-image" />
          </div>
        </kinesis-element>
      </kinesis-container>
    </div>
    <DemoWishlistView />
    <div class="additional-content">
      <h2 class="content-title">{{ $t('main.socialTitle') }}</h2>
      <img :src="socialImage" class="content-image" />
    </div>
  </div>
</template>

<script>
import StackLayout from './StackLayout.vue';
// import MultiLineTextView from './MultiLineTextView.vue';
import DemoWishlistView from './DemoWishlistView.vue';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import { useI18n } from "vue-i18n";
import { initializeKinesis } from '@amineyarman/kinesis'

export default {
  name: 'MainPage',
  data() {
    return {
      ladyImage: require(`@/assets/lady.png`),
      leftPresents: [
        { src: require(`@/assets/present1.png`), top: "70%", right: "70%" },
        { src: require(`@/assets/present2.png`), top: "15%", right: "80%" },
      ],
      topPresents: [
        { src: require(`@/assets/present3.png`), bottom: "80%", left: "20%" },
        { src: require(`@/assets/present4.png`), bottom: "75%", left: "60%" },
      ],
      rightPresents: [
        { src: require(`@/assets/present5.png`), top: "30%", left: "80%" },
        { src: require(`@/assets/present6.png`), top: "70%", left: "70%" },
      ],
    };
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", () => {
      initializeKinesis();
    });
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
    socialImage() {
      return require(`@/assets/social-${this.isMobile ? 'mobile' : 'desktop'}.png`);
    },
    isMobile() {
      return window.innerWidth <= 767;
    },
    domain() {
      return window.location.hostname;
    },
    lines() {
      const { t } = useI18n();
      return [
        [
          ...(this.isMobile && this.currentLocale !== "kk"
            ? [{ type: "text", props: { text: t('main.tags-prefix') } }]
            : []),
          {
            type: "custom",
            props: {
              text: t('main.tag-birthday'),
              icon: "icon-tag-gift.png",
              borderColor: "#EB8B3B",
            },
          },
          {
            type: "custom",
            props: {
              text: t('main.tag-eightMarch'),
              icon: "icon-tag-flowers.png",
              borderColor: "#FF69D9",
            },
          },
          {
            type: "custom",
            props: {
              text: t('main.tag-genderParty'),
              icon: "icon-tag-bottle.png",
              borderColor: "#2F80DE",
            },
          },
        ],
        [
          {
            type: "custom",
            props: {
              text: t('main.tag-wedding'),
              icon: "icon-tag-widding.png",
              borderColor: "#2F80DE",
            },
          },
          {
            type: "custom",
            props: {
              text: t('main.tag-christmas'),
              icon: "icon-tag-christmas.png",
              borderColor: "#37823D",
            },
          },
        ],
      ];
    }
  },
  components: {
    StackLayout,
    DemoWishlistView,
    // MultiLineTextView,
  },
  methods: {
  },
  setup() {
    const { t } = useI18n();
    const domain = window.location.hostname;
    const commonHeadConfig = {
      link: [
        { rel: 'icon', href: '/favicon.ico' },
      ],
      title: t('meta.title'),
      meta: [
        { name: 'description', content: t('meta.description') },
        { property: 'og:title', content: t('meta.ogTitle') },
        { property: 'og:description', content: t('meta.ogDescription') },
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'application-name', content: 'Wisher Pro' },
        { property: 'og:image', content: '/favicon.ico' }
      ],
    };
    if (domain === 'wisher-test.pro') {
      useHead({
        ...commonHeadConfig,
        meta: [
          ...commonHeadConfig.meta,
          { name: 'robots', content: 'noindex, nofollow' },
        ],
      });
    } else if (domain === 'wisher.pro') {

      const route = useRoute();
      const hreflang = route.meta.hreflang;
      const links = hreflang
        ? Object.entries(hreflang).map(([lang, href]) => ({
          rel: 'alternate',
          hreflang: lang === 'default' ? 'x-default' : lang,
          href,
        })) : [];

      useHead({
        ...commonHeadConfig,
        title: t('meta.title'),
        meta: [
          ...commonHeadConfig.meta,
          { name: 'description', content: t('meta.description') },
          { property: 'og:title', content: t('meta.ogTitle') },
          { property: 'og:description', content: t('meta.ogDescription') },
          { property: 'og:url', content: 'https://wisher.pro' },
          { property: 'og:image', content: 'https://wisher.pro/favicon.ico' }
        ],
        link: [
          ...(commonHeadConfig.link || []),
          ...links,
        ],
      });
    } else {
      useHead(commonHeadConfig);
    }
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.ny-promo-button {
  margin-top: 40px;
  margin-bottom: 30px;
}

.title {
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  color: #1B202A;
}

.multiline-text-view {
  margin-top: 20px;
  margin-bottom: 60px;
}

.responsive-image {
  width: 70%;
  max-width: 100%;
  height: auto;
  margin-top: 120px;
  margin-bottom: 200px;
}

.kinesis-wrapper {
  position: relative;
  width: 70%;
  max-width: 100%;
  margin-top: 120px;
  margin-bottom: 100px;
}

.lady-image {
  position: relative;
  max-width: 100%;
  height: auto;
  z-index: 1;
}

.additional-content {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 40px;
}

.content-title {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 80px;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  color: #1B202A;
}

.content-image {
  width: 90%;
  max-width: 100%;
  height: auto;
}

.present {
  position: absolute;
  z-index: 2;
}

.present-image {
  width: 150px;
}

@media (max-width: 767px) {
  .title {
    font-size: 52px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    color: #1B202A;
  }

  .multiline-text-view {
    margin-left: 4px;
    margin-right: 4px;
  }

  .responsive-image {
    width: 80%;
    margin-bottom: 100px;
  }

  .kinesis-wrapper {
    position: relative;
    width: 90%;
    margin-top: 160px;
    margin-bottom: 80px;
  }

  .lady-image {
    position: relative;
    z-index: 1;
  }

  .content-title {
    font-size: 30px;
    color: #1B202A;
  }

  .content-image {
    width: 80%;
  }

  .present {
    position: absolute;
    z-index: 2;
  }

  .present-image {
    width: 120px;
  }
}
</style>

<style>
body {
  background-color: #FFFFFF;
  margin: 0;
}
</style>
