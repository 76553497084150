<template>
  <header>
    <nav>
      <router-link to="/">
        <img src="@/assets/header-logo.png" alt="Home" class="header-logo">
      </router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader'
}
</script>

<style scoped>
header {
  background-color: #FFFFFF;
  width: 100vw;
  padding: 0.5rem 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.header-logo {
  height: 40px;
  width: auto;
  cursor: pointer;
}
</style>
